<template>
  <div>
    <EmployeeLayout v-if="true">
      <b-card
        class="projects-card"
        align="left"
      >
        <b-row>
          <b-col>
            <h4>Equipo</h4>
          </b-col>
          <b-col>
            <router-link
              :to="{ name: 'employee-create' }"
              class="float-right"
            >
              <img :src="require('@/assets/icons/create-icon.svg')">
            </router-link>
          </b-col>
        </b-row>
        <b-row v-if="!employees || employees.length == 0">
          <b-col>
            <strong>¡Vaya!</strong>
            <div>Parece que no tienes personal.</div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <b-container>
              <b-row v-for="employee in employees" :key="employee.id" class="project-row align-items-center">
                <b-col>
                  <span>
                    {{ employee.firstName + " " + employee.lastName }}
                  </span>
                </b-col>
                <b-col>
                  <span class="ml-auto edit-card-info-image" @click="onEditEmployee(employee)"></span>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-card>
    </EmployeeLayout>
  </div>
</template>

<script>
import EmployeeLayout from '../layout/EmployeeLayout.vue'

export default {
  name: 'EmployeeList',
  components: { EmployeeLayout },
  async created () {
    await this.$store.dispatch('employee/getAll')
  },
  computed: {
    employees () {
      return this.$store.getters['employee/all']
    }
  },
  methods: {
    onEditEmployee (employee) {
      this.$router.push({
        name: 'employee-edit',
        params: {
          id: employee.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import 'employeeList.less';
</style>
