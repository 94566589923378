<template>
  <span>
    <span v-if="available" class="badge badge-pill available">Disponible</span>
    <span v-else class="badge badge-pill unavailable">No disponible</span>
  </span>
</template>

<script>
export default {
  name: 'AvailableBadge',
  props: {
    available: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="less" scoped>
  .badge {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    padding: 5px 7px;
    line-height: 1;
    background: #1C2340CC;
    background-blend-mode: multiply;
    &.available {
      &::before {
        content: " ";
        background: #7EC856;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        display: inline-block;
        margin-right: 0.3rem;
      }
    }
    &.unavailable {
      &::before {
        content: " ";
        background: red;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        display: inline-block;
        margin-right: 0.3rem;
      }
    }
  }
</style>
